import Script from 'next/script'
import useSWR from 'swr'
import { Footer } from '@/components/common/Footer'
// import { Header } from '@/components/common/Header'
import ComponentGlobalBanner, {
  fetchGlobalBanner,
} from './pageBlocks/ComponentGlobalBanner'
import { useRouter } from 'next/router'
import { ComponentType, createContext, useEffect, useState } from 'react'
import PreviewModeBanner from './common/PreviewModeBanner'
import { useManualScrollRestoration } from 'hooks/useManualScrollRestoration'
import { SharedAdvisorContactCTAProps } from './advisors/AdvisorCTAs'
import dynamic from 'next/dynamic'
import { HeaderProps } from '@/components/common/Header'
import { useSmoothScrollOnSamePageLinksOnly } from 'hooks/useSmoothScrollOnSamePageLinksOnly'

const Header: ComponentType<HeaderProps> = dynamic(
  (): any => import('@/components/common/Header'),
  {
    ssr: false,
  }
)

interface LayoutProps {
  children: React.ReactNode
  showHeader?: boolean
  hideFooter?: boolean
  hideGlobalBanner?: boolean
  showPreviewBanner?: boolean
  collapseHeaderMargin?: boolean
  collapseFooterMargin?: boolean
  expanded?: boolean
  advisor?: SharedAdvisorContactCTAProps | undefined
  menuLinks?: any
}

export const SidebarContext = createContext<any>({})
export interface DestinationsCache {
  expiration: number
  categories: any[]
}

export function Layout({
  children,
  showHeader = true,
  hideFooter = false,
  hideGlobalBanner = false,
  showPreviewBanner = false,
  collapseHeaderMargin = false,
  collapseFooterMargin = false,
  advisor,
  menuLinks,
  expanded = true,
}: LayoutProps): JSX.Element {
  const router = useRouter()
  const { data: banner, mutate } = useSWR('/api/banner', fetchGlobalBanner)

  useEffect(() => {
    if (router && router.isReady) mutate()
  }, [router, mutate])

  useSmoothScrollOnSamePageLinksOnly()

  useManualScrollRestoration()

  const HubSpotPixel = () => {
    if (process.env.NEXT_PUBLIC_HUBSPOT_PIXEL_ID === undefined) return <></>
    return (
      <Script
        strategy="afterInteractive"
        id="hs-script-loader"
        async
        defer
        src={`//js.hs-scripts.com/${process.env.NEXT_PUBLIC_HUBSPOT_PIXEL_ID}.js`}
      />
    )
  }

  const [isBannerClosed, setIsBannerClosed] = useState<boolean>(
    !banner?.isActive
  )

  const handleBannerDisplay = (newValue: boolean) => {
    // Update the close state from the global banner
    setIsBannerClosed(newValue)
  }

  return (
    <>
      {banner?.isActive && !hideGlobalBanner && (
        <ComponentGlobalBanner
          {...banner}
          advisor={advisor || undefined}
          handleBannerDisplay={handleBannerDisplay}
        />
      )}
      {showPreviewBanner ? <PreviewModeBanner /> : null}
      <div className="max-w-[1296px] px-4 m-auto lg:px-12">
        {/* scripts in <body> */}
        <Script id="fb-pixel" strategy="afterInteractive">
          {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');
        fbq('track', 'PageView');
        `}
        </Script>
        <Script id="linkedIn-partnerId" strategy="afterInteractive">
          {`
          _linkedin_partner_id = "${process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID}";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        `}
        </Script>
        <Script id="linkedIn-pixel" strategy="afterInteractive">
          {`
          (function(l) {
            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
            window.lintrk.q=[]}
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})(window.lintrk);
        `}
        </Script>
        <Script
          src={`//rum-static.pingdom.net/${process.env.NEXT_PUBLIC_PINGDOM_ID}.js`}
          strategy="afterInteractive"
          async
        />

        {showHeader && (
          <Header
            collapseMargin={collapseHeaderMargin}
            advisor={advisor || undefined}
            menuLinks={menuLinks}
            expanded={expanded}
            isBannerClosed={isBannerClosed}
          />
        )}
        <main>{children}</main>

        {!hideFooter && (
          <Footer
            collapseMargin={collapseFooterMargin}
            advisor={advisor || undefined}
          />
        )}
        <HubSpotPixel />
      </div>
    </>
  )
}
