import { fetchContent } from '@/utils/FetchContent'
import { ReviewsSWRKey, formatStarsIn } from '@/utils/SWR'
import {
  DisplayTestimonial,
  TestimonialsData,
  defaultOrder,
} from '@/components/pageBlocks/Reviews'

export const REVIEWS_LIMIT = 4

export function formatAverage(num) {
  const truncate = (Math.floor(num * 100) / 100).toFixed(2)
  if (truncate.endsWith('0')) return truncate.slice(0, -1)
  else return truncate
}

export function isTestimonialsData(data: any): data is TestimonialsData {
  return data?.total && data?.items
}

type FetcherFunc = ([
  offset,
  limit,
  order,
  starsIn,
  slug,
]: ReviewsSWRKey) => Promise<TestimonialsData>

export interface IFetcher {
  fetch: FetcherFunc
}

export const ReviewsTestimonialFragment = `
  items {
    stars
    advisor {
      slug
      image {
        url
        description
      }
      cloudinaryImage
      firstName
      lastName
    }
    dateSubmitted
    display
    submitterFirstName
    submitterLastName
    destinationsSummary {
      json
    }
    testimonial {
      json
    }
    submittedTestimonial
  }
  total
`

export class AllFetcher implements IFetcher {
  constructor() {
    this.fetch = this.fetch.bind(this)
  }
  async fetch([_e, skip, _l, order, starsIn]: ReviewsSWRKey) {
    const data = await fetchContent(this.makeQuery(skip, order, starsIn))
    const reviewsData = this.dataAccessor(data)
    return reviewsData
  }

  dataAccessor(data: any) {
    try {
      if (isTestimonialsData(data?.entryTestimonialCollection)) {
        return data?.entryTestimonialCollection
      } else {
        return { total: 0, items: [] }
      }
    } catch (e) {
      return { total: 0, items: [] }
    }
  }

  makeQuery(skip: number, order: string, starsIn: number[]) {
    return `
      query {
        entryTestimonialCollection(
          skip: ${skip}, 
          limit: ${REVIEWS_LIMIT}, 
          where: { 
            display: true, 
            stars_in: ${JSON.stringify(formatStarsIn(starsIn))} 
          },
          order: [${order}],
        ) {
          ${ReviewsTestimonialFragment}
        }
      }
    `
  }
}

export class DestinationFetcher {
  slug: string
  slugType: 'category' | 'subcategory'
  collection:
    | 'destinationsCategoriesCollection'
    | 'destinationsSubcategoriesCollection'
  _skip: number
  hasFetched: boolean
  cache: DisplayTestimonial[]
  starAgg: number[] = [0, 0, 0, 0, 0]
  fetchKey: string

  constructor(slug: string, slugType: 'category' | 'subcategory') {
    this.slug = slug
    this.slugType = slugType
    this.collection =
      slugType === 'category'
        ? 'destinationsCategoriesCollection'
        : 'destinationsSubcategoriesCollection'
    this.fetch = this.fetch.bind(this)
    this._skip = 0

    this.hasFetched = false
    this.cache = []
    this.fetchKey = ''
  }
  async fetch([
    _e,
    skip,
    _l,
    order,
    starsIn,
    slug,
  ]: ReviewsSWRKey): Promise<TestimonialsData> {
    //  Server side ratings filtering not interoperable with location filtering
    // Get all testimonials associated with the category then implement filtering
    if (
      !this.hasFetched ||
      this.fetchKey !== JSON.stringify([order, starsIn, slug])
    ) {
      this._skip = 0
      this.fetchKey = JSON.stringify([order, starsIn, slug])
      await this.populateCache([_e, this._skip, _l, order, starsIn, slug])
    }
    return { total: this.cache.length, items: this.cache.slice(skip, skip + 4) }
  }

  async populateCache([_e, skip, _l, order, starsIn, slug]: ReviewsSWRKey) {
    //  Server side ratings filtering not interoperable with location filtering
    // Get all testimonials associated with the category then implement filtering

    const data = await fetchContent(
      this.makeQuery(skip, order, starsIn, slug as string)
    )
    const reviewsData = this.dataAccessor(data)
    const { items } = reviewsData
    for (let i = 0; i < items.length; i++) {
      if (items[i].display && starsIn.includes(items[i].stars ?? 0)) {
        this.cache.push(items[i] as DisplayTestimonial)
      }
    }
    this.hasFetched = true
    return reviewsData
  }

  async aggregate() {
    if (!this.hasFetched) {
      await this.populateCache([
        '',
        0,
        0,
        defaultOrder,
        [5, 4, 3, 2, 1],
        this.slug,
      ])
    }
    let totalStars = 0
    this.cache.forEach((review) => {
      if (typeof review.stars === 'number' && review.stars <= 5) {
        this.starAgg[review.stars - 1]++
        totalStars += review.stars
      }
    })
    const averageRating = totalStars / this.cache.length
    return {
      totalCount: this.cache.length,
      counts: this.starAgg,
      averageRating,
    }
  }

  dataAccessor(data: any): TestimonialsData {
    try {
      if (
        isTestimonialsData(
          data[this.collection].items[0].linkedFrom.entryTestimonialCollection
        )
      ) {
        return data[this.collection].items[0].linkedFrom
          .entryTestimonialCollection
      } else {
        return { total: 0, items: [] }
      }
    } catch (e) {
      console.log(e)
      return { total: 0, items: [] }
    }
  }

  makeQuery(
    skip: number,
    order: string,
    starsIn: number[],
    slug: string // Allow optional never used slug to match other queries
  ) {
    return `
    {
      ${this.collection}(where: {slug: "${slug}"}, limit: 1) {
        items {
          linkedFrom {
          entryTestimonialCollection(
            skip: ${skip}, 
            limit: 200, 
            order: [${order}],
          ) {
            
            ${ReviewsTestimonialFragment}
      total
          }
          }
        }
      }
    }
      `
  }
}
