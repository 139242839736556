import Image from 'next/legacy/image'
import { contentfulImageLoader, imageLoader } from '@/utils/ImageLoaders'
import { AdvisorExpertiseList } from '../advisors/AdvisorExpertiseList'
import React from 'react'
import { ButtonLink } from '@/components/ui/ButtonLinks'
import Link from 'next/link'
import { AdvisorVariants, checkifIsProfile } from '../advisors/Types'
import { CloudinaryAsset } from '../cloudinary/Types'

export type EmbeddedAdvisorCardProps = {
  title: string
  firstName: string
  slug: string
  image: {
    url: string
  }
  expertise: string[]
  advisorVariant: AdvisorVariants
}

const getEmbeddedCardEyebrow = (advisorVariant: AdvisorVariants) => {
  let response = `ADVISOR`
  switch (advisorVariant) {
    case AdvisorVariants.FORA:
      response = `FORA`
      break
    case AdvisorVariants.PARTNER:
      response = `PARTNER`
      break
  }
  return response
}

export function EmbeddedAdvisorCard({
  slug,
  title,
  image,
  expertise,
  advisorVariant,
}: EmbeddedAdvisorCardProps): JSX.Element {
  return (
    <div className="py-8 text-center md:py-12 lg:text-left lg:px-10 lg:flex bg-darkSand advisor-embedded-card">
      <Link
        href={
          checkifIsProfile(advisorVariant)
            ? `/profile/${slug}`
            : `/advisor/${slug}`
        }
        className="flex flex-col items-center px-16 text-center lg:w-1/2 lg:px-0"
      >
        <div className="pb-5 lg:hidden">
          <Image
            className="block m-auto lg:h-full"
            loader={({ src }) =>
              imageLoader({
                src: src,
                width: 59,
                quality: 90,
              })
            }
            src="https://media.foratravel.com/image/upload/v1715287680/fora-website-assets/snp9bmzrjdxxdjlivbm8.svg"
            alt="Fora - Home"
            width={59}
            height={18}
          />
        </div>
        <div className="inline-block w-40 pb-5 text-center">
          <Image
            className="rounded-full"
            loader={() =>
              contentfulImageLoader({
                src: image?.url,
                width: 320,
                height: 320,
                quality: 90,
                fit: 'fill',
                format: 'webp',
              })
            }
            src={image?.url}
            alt={`Advisor - ${title}`}
            layout="responsive"
            width={320}
            height={320}
            sizes="25vw"
          />
        </div>
        <div className="pb-1 fora-text-eyebrow-4">
          {getEmbeddedCardEyebrow(advisorVariant)}
        </div>
        <div className="pb-6 md:fora-text-h7 fora-text-h4 lg:pb-0">{title}</div>
      </Link>

      <div className="relative w-64 m-auto text-center lg:m-0 lg:w-1/2 lg:text-left md:flex md:flex-col md:justify-between">
        <div>
          <div className="hidden pb-6 lg:block">
            <Image
              className="block w-2/3 m-auto lg:h-full"
              loader={({ src }) =>
                imageLoader({
                  src: src,
                  width: 75,
                  quality: 90,
                })
              }
              src="https://media.foratravel.com/image/upload/v1715287680/fora-website-assets/snp9bmzrjdxxdjlivbm8.svg"
              alt="Fora - Home"
              width={75}
              height={23}
            />
          </div>
          <div className="pb-0.5 lg:pb-2 uppercase fora-text-eyebrow-4">
            expertise
          </div>
          <div className="pb-3 mb-5 lg:pb-0">
            <AdvisorExpertiseList
              {...{
                expertise: expertise,
                numberToShow: 4,
              }}
            />
          </div>
        </div>
        <div className="inline-block w-4/5 text-center lg:w-full lg:bottom-0 lg:flex">
          <ButtonLink
            href={
              checkifIsProfile(advisorVariant)
                ? `/profile/${slug}`
                : `/advisor/${slug}`
            }
            target="_self"
            text="View Profile"
            theme="transparent"
            isFull={true}
          />
        </div>
      </div>
    </div>
  )
}

export type EmbeddedItineraryCardProps = {
  __typename: string
  title: string
  slugId: string
  seoSlug: string
  image: {
    url: string
  }
  advisor: {
    title: string
    image: {
      url: string
    }
    cloudinaryImage: null | CloudinaryAsset[]
  }
}

export function EmbeddedItineraryTripReportCard({
  __typename,
  title,
  slugId,
  seoSlug,
  image,
  advisor,
}: EmbeddedItineraryCardProps): JSX.Element {
  const contentName = __typename === 'EntryTripReport' ? 'Trip Report' : 'Guide'
  const cardLink = `/${
    __typename === 'EntryTripReport' ? 'trip-reports' : 'guides'
  }/${slugId}/${seoSlug}`
  return (
    <div className="justify-center p-6 lg:flex bg-darkSand guide-embedded-card">
      <div className="lg:pr-6">
        <div className="pb-6 text-center lg:hidden">
          <Image
            className="block w-2/3 m-auto lg:h-full"
            loader={({ src }) =>
              imageLoader({
                src: src,
                width: 59,
                quality: 90,
              })
            }
            src="https://media.foratravel.com/image/upload/v1715287680/fora-website-assets/snp9bmzrjdxxdjlivbm8.svg"
            alt="Fora - Home"
            width={59}
            height={18}
          />
        </div>
        <div className="pb-4 text-center lg:pb-0 lg:w-[264px]">
          <Link href={cardLink}>
            <Image
              loader={() =>
                contentfulImageLoader({
                  src: image?.url,
                  width: 416,
                  height: 516,
                  quality: 90,
                  fit: 'crop',
                  format: 'webp',
                })
              }
              src={image?.url}
              alt={`Advisor - ${title}`}
              layout="responsive"
              width={416}
              height={516}
              sizes="40vw"
            />
          </Link>
        </div>
      </div>
      <div className="relative place-self-center">
        <div className="hidden pb-4 lg:block">
          <Image
            className="block w-2/3 m-auto lg:h-full"
            loader={({ src }) =>
              imageLoader({
                src: src,
                width: 75,
                quality: 90,
              })
            }
            src="https://media.foratravel.com/image/upload/v1715287680/fora-website-assets/snp9bmzrjdxxdjlivbm8.svg"
            alt="Fora - Home"
            width={75}
            height={23}
          />
        </div>
        {}
        <Link href={cardLink}>
          <div
            className="pb-3 fora-text-h4 md:fora-text-h6"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </Link>
        <div className="flex items-center gap-4 pb-4 lg:pb-0 lg:mb-6">
          <div className="w-12">
            {advisor?.cloudinaryImage && (
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 96,
                    quality: 90,
                  })
                }
                className="rounded-full"
                src={advisor?.cloudinaryImage[0].secure_url}
                data-src={advisor?.cloudinaryImage[0].secure_url}
                alt={`Fora Advisor - ${advisor?.title}`}
                layout="responsive"
                width={96}
                height={96}
                sizes="10vw"
                quality={90}
              />
            )}
          </div>
          <div>
            <div className="fora-text-body-italic-3">Curated by</div>
            <div className="md:fora-text-h7 fora-text-h5">{advisor.title}</div>
          </div>
        </div>
        <div className="lg:w-full lg:bottom-0">
          <ButtonLink
            text={`explore this ${contentName}`}
            target="_self"
            href={cardLink}
            theme="transparent"
            isFull={true}
          />
        </div>
      </div>
    </div>
  )
}
