import fetch from 'cross-fetch'
// Uncomment below when running locally
// import dotenv from 'dotenv'
// dotenv.config({ path: '.env.local' })

type EnvConfig = {
  spaceConfig: string
  accessTokenConfig: string
  environmentConfig: string
  previewTokenConfig: string
}

const space = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID
const environment = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT
const accessToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN
const previewToken = process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN

export async function fetchContent<T = any>(
  query: string,
  isPreview = false,
  // Extends historic configurability with a env string variable by allowing
  // an object to be passed, makes the function usable in script by passing a loaded env file
  // use of a string is still supported for configuring previews.
  envConfig: EnvConfig | string | null = {
    spaceConfig: space || '',
    accessTokenConfig: accessToken || '',
    environmentConfig: environment || '',
    previewTokenConfig: previewToken || '',
  }
) {
  let spaceConfig = space
  let environmentConfig = environment
  let accessTokenConfig = accessToken
  let previewTokenConfig = previewToken

  if (envConfig && typeof envConfig === 'object') {
    spaceConfig = envConfig?.spaceConfig
    environmentConfig = envConfig?.environmentConfig
    accessTokenConfig = envConfig?.accessTokenConfig
    previewTokenConfig = envConfig?.previewTokenConfig
  } else if (typeof envConfig === 'string') {
    environmentConfig = envConfig
  }

  const includeEnvironment = environmentConfig && environmentConfig !== 'master'
  const url = `https://graphql.contentful.com/content/v1/spaces/${spaceConfig}${
    includeEnvironment ? `/environments/${environmentConfig}` : ''
  }`

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${
          isPreview ? previewTokenConfig : accessTokenConfig
        }`,
      },
      body: JSON.stringify({ query }),
    })

    if (res.status === 400) {
      console.log(`Error: Contentful fetchContent() for ${query}`)
    }
    const { data } = await res.json()
    return data as T
  } catch (error) {
    console.error(`Error: Contentful fetchContent() for ${query}`)
    console.error(error)
  }
}

type PageItem = {
  slug: string
  lastMod: string
  publishedAt?: string
  title: string
  [key: string]: any
}

type FetchDynamicPagesOptions = {
  collection: string
  slugField?: string
  includeNoIndexNofollow?: boolean
  includeSlugID?: boolean
  includeFieldPublishDate?: boolean
  furtherItemsFragment?: string
  furtherItemsKeys?: string[]
  filterFragment?: string
}

export const fetchDynamicPages = async ({
  collection,
  slugField = 'slug',
  includeNoIndexNofollow = false,
  includeSlugID = false,
  includeFieldPublishDate = false,
  furtherItemsFragment = '',
  furtherItemsKeys = [],
  filterFragment = '',
}: FetchDynamicPagesOptions): Promise<PageItem[]> => {
  const collectionString = `${collection}Collection`
  let total = 0
  let skip = 0
  const limit = 100
  const pageCollection: PageItem[] = []

  do {
    const data = await fetchContent<{
      [key: string]: {
        total: number
        items: PageItem[]
      }
    }>(`
      query {
        ${collectionString}(limit: ${limit}, skip: ${skip}, ${filterFragment}){
          total 
          items {
            ${slugField}
            ${includeSlugID ? `slugId` : ''}
            ${furtherItemsFragment}
            sys {
              publishedAt
              firstPublishedAt
              id
            }
            ${includeNoIndexNofollow ? 'noindexNofollow' : ''}
            ${includeFieldPublishDate ? 'publishedDate' : ''}
          }
        }
      }`)

    total = data?.[collectionString]?.total || 0
    skip += limit
    pageCollection.push(...(data?.[collectionString]?.items || []))
  } while (pageCollection.length < total)

  return pageCollection
    .filter((page) => !page.noindexNofollow)
    .map((page) => {
      const slug = `${includeSlugID ? `${page.slugId}/` : ''}${
        page[slugField] || ''
      }`
      const result: PageItem = {
        slug,
        lastMod: page.publishedDate ? page.publishedDate : page.sys.publishedAt,
        publishedAt: page.sys.firstPublishedAt,
        title: page.title,
      }
      furtherItemsKeys.forEach((key) => {
        result[key] = page[key]
      })
      return result
    })
}
