import {
  ItineraryTripReportCardProps,
  TripReportCardProps,
} from '@/components/itineraries/Types'
import {
  TravelBySubcategoriesProps,
  DestinationsSubcategoriesProps,
} from '../blogs/Types'
import { HotelProps } from '../itineraries/Hotel'
import { ComponentImage } from 'types/generated/contentful-types'
import { CloudinaryAsset } from '../cloudinary/Types'

export type AdvisorsProps = {
  advisors: [AdvisorProps]
}

type AdvisorsLinkedFrom = {
  itinerariesCollection: {
    items: [ItineraryTripReportCardProps]
  }
  entryTestimonialCollection: {
    items: [AdvisorTestimonial]
  }
  entryTripReportCollection: {
    items: [ItineraryTripReportCardProps]
  }
}

export type AdvisorBySlugProps = {
  advisor: AdvisorProps
  linkedFrom: AdvisorsLinkedFrom
  globalConfigs: {
    advisorsMetaDescription: string
  }
  isPreview: boolean
  menuLinks: any
}

export type AdvisorCardSmallProps = {
  sys: {
    id: string
  }
  tier?: string
  title: string
  pronouns: string
  slug: string
  lastName?: string
  advisorVariant: AdvisorVariants
  image: {
    url: string
    description: string
  }
  cloudinaryImage: null | CloudinaryAsset[]
  expertise: string[]
  categoriesCollection: {
    items: [TravelBySubcategoriesProps | DestinationsSubcategoriesProps]
  }
  showPronouns?: boolean
}

export type AdvisorCardProps = {
  name: string
  title: string
  image: {
    url: string
    description: string
  }
}

export type AdvisorProps = {
  sys: { id: string; publishedAt?: string; firstPublishedAt?: string }
  title: string
  tier: string
  slug: string
  advisorVariant: AdvisorVariants
  advisorContactEnabled: boolean
  firstName: string
  lastName: string
  pronouns: string
  emailAddress: string
  image: {
    url: string
    description: string
  }
  cloudinaryImage: null | CloudinaryAsset[]
  expertise: string[]
  categoriesCollection: {
    items: [TravelBySubcategoriesProps | DestinationsSubcategoriesProps]
  }
  basedIn: {
    json: any
  }
  languagesSpoken: {
    json: any
  }
  minimumBooking: number
  aboutMeIAm: {
    json: any
  }
  aboutMeHomeAirport: {
    json: any
  }
  myTravelStyleQuote: {
    json: any
  }
  myTravelStyleRecentlyReturnedFrom: {
    json: any
  }
  myTravelStyleDreamingOf: {
    json: any
  }
  myTravelStyleCantLiveWithout: {
    json: any
  }
  myTravelStyleTwoTruthsOneLie: {
    json: any
  }
  hotelsCollection: {
    items: [HotelProps]
  }
  preferredGuidesCollection: {
    items: [ItineraryTripReportCardProps]
  }
  linkedFrom?: {
    itinerariesCollection: {
      items: [ItineraryTripReportCardProps]
    }
    entryTestimonialCollection: {
      items: [AdvisorTestimonial]
    }
    entryTripReportCollection: {
      items: [TripReportCardProps]
    }
  }
  instagramUrl?: string
  tikTokUrl?: string
  facebookUrl?: string
  twitterUrl?: string
  websiteUrl?: string
  pinterestUrl?: string
  linkedinUrl?: string
  youtubeUrl?: string
  imageCarouselCollection?: {
    items: [ComponentImage]
  }
  mailChimpForm: string
}

export enum AdvisorVariants {
  PERSON = 'Person',
  COMPANY = 'Company',
  FORA = 'Fora',
  CREATOR = 'Creator',
  TASTEMAKER = 'Tastemaker',
  PARTNER = 'Partner',
}

export function checkifIsProfile(variant: AdvisorVariants): boolean {
  return (
    variant === AdvisorVariants.FORA ||
    variant === AdvisorVariants.CREATOR ||
    variant === AdvisorVariants.PARTNER ||
    variant === AdvisorVariants.TASTEMAKER
  )
}

export interface AdvisorTestimonial {
  display: boolean
  stars: number
  testimonial: {
    json: any
  }
  submitterFirstName: string
  submitterLastName: string
  submitterImage: {
    url: string
  }
  destinationsSummary: {
    json: any
  }
  dateSubmitted: string
  submittedTestimonial?: string
}
